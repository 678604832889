body {
  overflow: hidden;
  overscroll-behavior: none;
}

.background {
  height: 100vh;
  left: 0;
  object-fit: cover;
  object-position: center top;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  will-change: transform;
  z-index: -1;
}

.dark .background {
  filter: brightness(25%);
}
